import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { H2, P } from '../Typography'

const Post = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;

  border: 1px solid rgba(196, 173, 238, 0.2);
  padding: 15px;

  max-width: 89rem;
  margin-left: auto;
  margin-right: auto;

  h2{margin-bottom: 0.5rem;}

  @media (max-width: 750px) {
    height: auto;
    padding: 0;
  }

  &.compact {
    ${H2} {
      font-size: 40px;
    }

    @media (max-width: 1000px) {
      ${H2} {
        font-size: 30px;
      }

      .excerpt {
        display: none;
      }
    }

    @media (max-width: 750px) {
      .excerpt {
        display: unset;
      }
    }
  }
`

const Text = styled.div`
  width: 40%;
  max-width: 70rem;
  position: absolute;
  right: 0;
  padding: 4rem 2rem;

  height: calc(100% - 30px);
    min-width: calc(30% - 14px);
    margin-right: 14px;

  background: rgba(21, 16, 41, 0.85);

  ${H2} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1200px) {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: 750px) {
    background: none;
    top: 0;
    right: 0;
    position: relative;
    width: 100%;
    min-width: unset;
    height: auto;
    padding: 20px;

    ${H2} {
      font-size: 2.6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .cretedAt {
      font-size: 2rem;
    }
  }
`

const Image = styled.figure`
  height: 0;
  width: calc(60% - 10px);
  padding-bottom: 35%;
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: calc(50% - 14px);
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  img {
    position: absolute;
    top: 0; left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 750px) {
    margin-bottom: 1rem;
  }
`

export default class FeaturedLink extends Component {
  render() {
    const { link, title, excerpt, image, date } = this.props
    const formattedDate = dayjs(date).format('MMM DD, YYYY')


    return (

      <Link to={`/news/${link}`}>
        <Post className={`${title.length > 24 ? 'compact' : ''}`}>
          <Image>
            <img alt='' src={image} />
          </Image>

          <Text>
            <P className="createdAt" color='var(--lilac)'>{formattedDate}</P>
            <H2 color='#fff'>{title}</H2>
            <P className="excerpt">{excerpt}</P>
          </Text>
        </Post>
      </Link>
    )
  }
}
