import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import HeaderButton from '../Header/HeaderButton'

import PostLink from './PostLink'

const Feed = styled.div`
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  padding: 2rem;
  a:nth-child(3) {
    .post { border-bottom: none; }
  }
  .viewMoreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
`

export default class PostsFeed extends PureComponent {
  render() {
    const { posts, className } = this.props

    return (
      <Feed className={className}>
        {posts.map((post, i) => {
          return (
            <PostLink
              key={i}
              allProps={post}
              image={post.node.feature_image}
              date={post.node.published_at}
              title={post.node.title}
              excerpt={post.node.excerpt}
              link={post.node.slug}
              tags={post.node.tags}
            />
          )
        })}
        <Link to="/news/" className="viewMoreButton">
          <HeaderButton>
            View All
          </HeaderButton>
        </Link>
      </Feed>
    )
  }
}