import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import { P, H2 } from '../Typography'

const SecondaryFeaturedLink = ({ post }) => {
    return (
        <Link to={`/news/${post.slug}`}>
            <Root>
                <div className="featuredImage" style={{ backgroundImage: `url(${post.feature_image})` }}></div>

                <div className="content">
                    <P className="publishedAt">
                        {dayjs(post.published_at).format('MMM DD, YYYY')}
                    </P>

                    <H2 className="title">
                        {post.title.toUpperCase()}
                    </H2>
                </div>
            </Root>
        </Link>
    )
}

const Root = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    transition: all 0.2s ease;
    height: 400px;
    border: 1px solid rgba(196, 173, 238, 0.2);

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding-top: 16px;
    }

    .featuredImage {
        height: 240px;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    @media (max-width: 1000px) {
        background-size: contain;
    }
    
    .title {
        font-size: 36px;
        margin-bottom: 0;
        color: #ffffff;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    :hover {
        background-color: rgba(196, 179, 242, 0.1)
    }

    @media (max-width: 800px) {
        .title {
            overflow: visible;
            font-size: 24px;
        }
        
        .content {
            padding: 20px;
        }

        .featuredImage {
            height: 160px;
            background-size: cover;
        }

        height: 240px;
        padding: 0;
    }
`

export default SecondaryFeaturedLink