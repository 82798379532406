import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { H2, P } from '../Typography'

const Post = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  border-bottom: 1px solid #403269;

  h2{margin-bottom: 0.5rem;}
`

const Text = styled.div`
  width: 65%;
  @media (max-width: 750px) {
    width: 100%;
  }
  overflow: hidden;
  position: relative;
`
const Image = styled.figure`
  width: 35%;
  padding-right: 1rem;
  img {width: 100%;}
  margin-top: 0.55rem;

  @media (max-width: 750px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 0.7rem;
  }
`

const PText = styled(P)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

export default class PostLink extends Component {
  render() {
    const { link, title, excerpt, image, date } = this.props

    const formattedDate = dayjs(date).format('MMM DD, YYYY')

    return (

      <Link to={`/news/${link}`}>
        <Post className="post">

          {image &&
            <Image>
              <img alt='News Post link' src={image} />
            </Image>
          }

          <Text>
            <P color='var(--lilac)'>{formattedDate}</P>
            <H2 color='#fff' size='2.3rem'>{title}</H2>

            <div style={{ overflow: 'hidden', position: 'relative' }}>
              <PText>
                {excerpt}
              </PText>
            </div>
          </Text>
        </Post>
      </Link>
    )
  }
}

PostLink.defaultProps = {
  date: '',
  title: '',
  excerpt: '',
  image: false,
  link: '/'
}