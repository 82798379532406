import React from "react";
import { graphql } from "gatsby";

import "../css/main.css";
import "../css/fonts.css";
import "../css/reset.css";

import Blog from "../components/Blog/";

const Index = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges;

  return <Blog posts={posts} />;
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query {
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          url
          slug
          html
          published_at
          tags {
            name
            slug
            url
          }
          title
          excerpt

          featured
          feature_image
        }
      }
    }
  }
`;
