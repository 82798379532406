import React, { PureComponent } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import BlogLayout from "./Layout";
import PostsFeed from "./PostsFeed";
import FeaturedHeader from "./FeaturedHeader";
import FeaturedLink from "./FeaturedLink";
import { P } from "../Typography";
import SecondaryFeaturedLink from "./SecondaryFeaturedLink";

const TAGS = ['news', 'annoucements', 'patch']

const ALL_POSTS_TAG = 'all posts'

export default class Blog extends PureComponent {
  state = {
    filteredTag: ALL_POSTS_TAG
  }

  render() {
    const { filteredTag } = this.state

    const featuredPosts = this.props.posts.filter(({ node: { featured } }) => featured)

    const recentPosts = this.props.posts.filter(({ node: { tags = [], featured } }) => {
      if (featured) {
        return false
      }

      const tagsStr = tags.map(({ name }) => name.replace('#', ''))

      if (tagsStr.includes('hidden')) {
        return false
      }

      if (filteredTag && TAGS.includes(filteredTag)) {
        return tagsStr.includes(filteredTag)
      }

      return true
    })

    const applicableTags = TAGS.filter((tag) => {
      const blogPost = this.props.posts.find(({ node: { tags = [] } }) => {
        const tagsStr = tags.map(({ name }) => name.replace('#', ''))

        return tagsStr.includes(tag)
      })

      return !!blogPost
    })

    const featuredPost = featuredPosts[0]
    const secondaryFeaturedPosts = featuredPosts.slice(1, 4)

    const latestPosts = recentPosts.filter(Boolean)

    return (
      <BlogLayout>
        <Helmet>
          <title>Skyweaver News | A Trading Card Game from Another Dimension</title>
        </Helmet>

        <div style={{ paddingBottom: 40 }}>
          <FeaturedHeader>Featured</FeaturedHeader>
        </div>

        <FeaturedLink
          allProps={featuredPost}
          image={featuredPost.node.feature_image}
          date={featuredPost.node.published_at}
          title={featuredPost.node.title}
          excerpt={featuredPost.node.excerpt}
          link={featuredPost.node.slug}
        />

        {secondaryFeaturedPosts.length !== 0 && (
          <SecondaryLinksContainer>
            {secondaryFeaturedPosts.map(({ node }) => {
              return (
                <SecondaryLinkContainer key={node.slug}>
                  <SecondaryFeaturedLink post={node} />
                </SecondaryLinkContainer>
              )
            })}
          </SecondaryLinksContainer>
        )}

        {latestPosts.length !== 0 && (
          <div style={{ paddingTop: 20 }}>
            <FeaturedHeader>Latest</FeaturedHeader>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
          {[ALL_POSTS_TAG, ...applicableTags].map(tag => {
            const selected = filteredTag === tag

            return (
              <P key={tag} onClick={() => {
                this.setState(({ filteredTag }) => {
                  return {
                    filteredTag: tag === filteredTag ? ALL_POSTS_TAG : tag
                  }
                })
              }}
                color="var(--lilac)"
                style={{
                  fontSize: 16,
                  fontWeight: selected ? 700 : 500,
                  opacity: !!filteredTag ? (selected ? 1 : 0.7) : 1,
                  margin: '0 20px',
                  cursor: 'pointer'
                }}>{tag.toUpperCase()}</P>
            )
          })}
        </div>

        <PostsFeed posts={latestPosts} />
      </BlogLayout>
    );
  }
}

const SecondaryLinkContainer = styled.div`
  flex: 1;
  width: calc(33.3% - 32px);
  max-width: calc(33.3% - 32px);
  margin: 0 16px;

  @media (max-width: 800px) {
    width: calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin: 0 4px;
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 6px 10px;
  }
`

const SecondaryLinksContainer = styled.div`
  margin-top: 30px;
  width: calc(89rem + 32px);
  justify-content: center;
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;

  @media (max-width: 800px) and (min-width: 600px) {
    > ${SecondaryLinkContainer}:last-of-type {
      display: none;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`

